<script>
import { get, omit } from 'lodash';
import Icon from '@components/Icon';

const creditCardIconList = [
	'american_express',
	'diners_club',
	'mastercard',
	'visa',
	'elo',
	'hipercard',
];

export default {
	name: 'UIInput',
	components: {
		Icon,
	},
	inheritAttrs: false,
	props: {
		validation: {
			type: Object,
			default: () => {}
		},
		label: {
			type: String,
			default: '',
			useDefaultForNull: true
		},
		id: {
			type: String,
			required: true
		},
		type: {
			type: String,
			default: 'text',
			useDefaultForNull: true
		},
		value: {
			type: [Number, String, Object, Boolean, Array],
			default: null,
		},
		error: {
			type: [String, Object],
			default: null,
		},
		minlength: {
			type: [String, Number],
			default: null,
		},
		maxlength: {
			type: [String, Number],
			default: null,
		},
		required: {
			type: [String, Boolean],
			default: false,
			useDefaultForNull: true
		},
		resized: {
			type: [Boolean],
			default: false,
			required: false,
			useDefaultForNull: true
		},
		minheight: {
			type: [String, Number],
			default: 100,
			required: false,
			useDefaultForNull: true
		},
		maxheight: {
			type: [String, Number],
			default: 200,
			required: false,
			useDefaultForNull: true
		},
		options: {
			type: [Array, Object],
			default: () => {},
			required: false,
			useDefaultForNull: true
		},
		placeholder: {
			type: String,
			default: '',
		},
		disabled: {
			type: [Boolean, String],
			default: false,
			required: false,
			useDefaultForNull: true
		},
		info: {
			type: [String, Object],
			default: '',
			required: false,
			useDefaultForNull: true
		},
		readonly: {
			type: [Boolean, String],
			default: false,
			required: false,
			useDefaultForNull: true
		},
		noStyle: {
			type: Array,
			default: () => [],
			useDefaultForNull: true,
		},
		creditCardIcon: {
			type: [String, Boolean],
			default: false,
			required: false
		},
		showCreditCardIcon: {
			type: [Boolean],
			default: false,
			required: false
		},
		valueAsKey: {
			type: [Boolean],
			default: false,
			required: false
		}
	},
	data() {
		return {
			showPassword: false,
			inputType: 'password',
			option: '',
			selected: '',
			className: ''
		};
	},
	computed: {
		controlProps: function() {
			return this.omit(this.$options.propsData, [
				'validation',
				'error',
				'info',
				'maxheight',
				'minheight',
				'type',
				'options',
				'label',
				'style',
				'class'
			]);
		},
		fieldLabel: function() {
			return [this.label, this.isRequired ? '*' : ''].join('');
		},
		isRequired: function() {
			return this.getAttributeValue('required');
		},
		classObject: function() {
			return {
				'is-danger': this.hasError() && !this.hasNoStyle('error'),
				'is-disabled': this.isDisabled() && !this.hasNoStyle('disabled'),
				'is-readonly': this.isReadonly() && !this.hasNoStyle('readonly'),
				'has-addons': this.hasSlot('addonBefore') || this.hasSlot('addonAfter'),
			};
		},
		isValidCreditCardIcon() {
			return creditCardIconList.includes(this.creditCardIcon)
		},
	},
	mounted() {
		this.className = this.$options.propsData.className = this.$el.className;
	},
	updated() {
		this.className = this.$options.propsData.className = this.$el.className;
	},
	methods: {
		toggleShowPassword() {
			this.showPassword = !this.showPassword;
			this.inputType = this.showPassword ? 'text' : 'password';
			this.$el.querySelector('[data-input-id="focus"]').focus();
		},
		hasError() {
			return !!this.get('validation.$error', false);
		},
		isDisabled() {
			return this.getAttributeValue('disabled');
		},
		isReadonly() {
			return this.getAttributeValue('readonly');
		},
		hasNoStyle(style) {
			return this.noStyle.includes(style);
		},
		hasSlot(slot) {
			if (this.$slots[slot]) {
				return true;
			}

			const scoped = this.$scopedSlot
				&& this.$scopedSlot[slot]
				&& this.$scopedSlot[slot]();

			return scoped && scoped.length;
		},
		getAttributeValue(value) {
			if (value in this.$options.propsData) {
				return String(this.get(value, '')).trim().length
					? this.get(value)
					: true;
			}
			return false;
		},
		get(value, defaultValue) {
			if (defaultValue) {
				return get(this.$options.propsData, value, defaultValue);
			}
			return get(this.$options.propsData, value);
		},
		omit(obj, keys) {
			return omit(obj, keys);
		},
		randomKey(key) {
			return `${Math.random()
				.toString(36)
				.slice(2)}-${JSON.stringify(key)}`;
		}
	}
};
</script>

<template>
	<div>
		<!--*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=
            - TEXT
        *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=-->
		<div
			v-if="type.match(/text$/)"
			class="ui-input"
			:class="classObject"
		>
			<label class="label">{{ fieldLabel }}</label>
			<div class="control float">
				<input
					v-bind="controlProps"
					class="input"
					:type="type"
					:value="value"
					@input="$emit('input', $event.target.value)"
				>
				<slot
					name="addonAfter"
					class="addon-after"
				/>
			</div>
			<p
				v-if="!hasError() && get('info')"
				class="help"
			>
				<span v-if="get('info') && !get('validation.$dirty')">
					{{ get('info') }}
				</span>
			</p>
			<p
				v-if="hasError()"
				class="help is-danger"
			>
				<span
					v-if="
						!get('validation.hasNotBeingUsed') &&
							get('validation.$model.length')
					"
				>
					{{ get('error.exists') }}
				</span>
				<span v-if="!get('validation.minLength')">
					{{ get('error.minLength') }}
				</span>
				<span
					v-if="
						!get('validation.invalid') &&
							get('validation.$model.length') &&
							get('validation.$model.minLength')
					"
				>
					{{ get('error.invalid') }}
				</span>
				<span
					v-if="
						get('validation.$invalid') &&
							get('validation.$model.length') &&
							!get('validation.date')
					"
				>
					{{ get('error.invalid') }}
				</span>
				<span
					v-if="!get('validation.required') && !get('validation.$model.length')"
				>
					{{ get('error.required') }}
				</span>
			</p>
			<pre v-if="false">{{ JSON.stringify(validation, null, 4) }}</pre>
		</div>

		<!--*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=
            - EMAIL
        *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=-->
		<div
			v-if="type.match(/email/gi)"
			class="ui-input"
			:class="classObject"
		>
			<label class="label">{{ fieldLabel }}</label>
			<div class="control float">
				<input
					v-bind="controlProps"
					class="input"
					:type="type"
					:value="String(value).toLowerCase()"
					@input="$emit('input', String($event.target.value).toLowerCase())"
				>
			</div>
			<p
				v-if="!hasError() && get('info')"
				class="help"
			>
				<span v-if="get('info') && !get('validation.$dirty')">
					{{ get('info') }}
				</span>
			</p>
			<p
				v-if="hasError()"
				class="help is-danger"
			>
				<span v-if="!get('validation.minLength')">
					{{ get('error.minLength') }}
				</span>
				<span
					v-if="
						!get('validation.invalid') &&
							get('validation.$model.length') &&
							get('validation.$model.minLength')
					"
				>
					{{ get('error.invalid') }}
				</span>
				<span v-if="!get('validation.email')">
					{{ get('error.invalid') }}
				</span>
				<span
					v-if="!get('validation.required') && !get('validation.$model.length')"
				>
					{{ get('error.required') }}
				</span>
				<span
					v-if="
						!get('validation.sameAsPassword') && get('validation.$model.length')
					"
				>
					{{ get('error.sameAs') }}
				</span>
				<span
					v-if="
						!get('validation.hasNotBeingUsed') &&
							get('validation.$model.length')
					"
				>
					{{ get('error.exists') }}
				</span>
			</p>
		</div>

		<!--*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=
            - CREDITCARD
        *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=-->
		<div
			v-if="type.match(/creditcard/gi)"
			class="ui-input ui-input-creditcard"
			:class="{ [className]: true, ...classObject }"
		>
			<label class="label">{{ fieldLabel }}</label>
			<div class="control float has-icons-right">
				<input
					v-bind="controlProps"
					data-input-id="focus"
					class="input"
					type="text"
					:value="value"
					@input="$emit('input', $event.target.value)"
				>

				<span
					v-if="showCreditCardIcon && value.length > 0 && isValidCreditCardIcon"
					class="icon is-relative has-text-success credit-card-icon"
				>
					<Icon
						class="is-inline-flex"
						:file="`pagamento/cartao_bandeira/${creditCardIcon}`"
						:size="'48:36'"
					/>
				</span>

				<b-icon
					v-else-if="showCreditCardIcon && value.length > 0"
					class="is-relative"
					:icon="
						creditCardIcon
							? 'credit-card-check'
							: hasError() || value.replace(/ /g, '').length > 13
								? 'credit-card-remove'
								: 'credit-card-settings'
					"
					:type="
						creditCardIcon
							? 'is-success'
							: hasError() || value.replace(/ /g, '').length > 13
								? 'is-danger'
								: ''
					"
				/>
			</div>
			<p
				v-if="!hasError() && get('info')"
				class="help"
			>
				<span v-if="get('info') && !get('validation.$dirty')">
					{{ get('info') }}
				</span>
			</p>
			<p
				v-if="hasError()"
				class="help is-danger"
			>
				<span
					v-if="
						!get('validation.hasNotBeingUsed') &&
							get('validation.$model.length')
					"
				>
					{{ get('error.exists') }}
				</span>
				<span
					v-if="!get('validation.invalid') && get('validation.$model.length')"
				>
					{{ get('error.invalid') }}
				</span>
				<span
					v-if="!get('validation.required') && !get('validation.$model.length')"
				>
					{{ get('error.required') }}
				</span>
				<span v-if="!get('validation.type') && get('validation.$model.length')">
					{{ get('error.type') }}
				</span>
				<span
					v-if="!get('validation.invalid') && get('validation.$model.length')"
				>
					{{ get('error.isValidCreditCard') }}
				</span>
				<span
					v-if="!get('validation.invalid') && get('validation.$model.length')"
				>
					{{ get('error.isValidCardExpiration') }}
				</span>
			</p>
		</div>

		<!--*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=
            - PASSWORD
        *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=-->
		<div
			v-if="type.match(/password/gi)"
			class="ui-input"
			:class="classObject"
		>
			<label class="label">{{ fieldLabel }}</label>
			<div class="control float has-icons-right">
				<input
					v-bind="controlProps"
					data-input-id="focus"
					class="input"
					:type="inputType"
					:value="value"
					@input="$emit('input', $event.target.value)"
				>

				<span
					class="icon is-small is-right"
					@click="toggleShowPassword"
				>
					<b-icon
						v-if="showPassword"
						icon="eye-off-outline"
					/>
					<b-icon
						v-else
						icon="eye-outline"
					/>
				</span>
			</div>
			<p
				v-if="!hasError() && get('info')"
				class="help"
			>
				<span v-if="get('info') && !get('validation.$dirty')">
					{{ get('info') }}
				</span>
			</p>
			<p
				v-if="hasError()"
				class="help is-danger"
			>
				<span v-if="!get('validation.minLength')">
					{{ get('error.minLength') }}
				</span>
				<span
					v-if="
						!get('validation.invalid') &&
							get('validation.$model.length') &&
							get('validation.$model.minLength')
					"
				>
					{{ get('error.invalid') }}
				</span>
				<span
					v-if="!get('validation.required') && !get('validation.$model.length')"
				>
					{{ get('error.required') }}
				</span>
				<span
					v-if="
						!get('validation.sameAsPassword') && get('validation.$model.length')
					"
				>
					{{ get('error.sameAs') }}
				</span>
				<span
					v-if="!get('validation.strong') && get('validation.$model.length')"
				>
					{{ get('error.strong') }}
				</span>
			</p>
		</div>
		<!--*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=
            - CPF E PHONE
        *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=-->
		<div
			v-if="type.match(/cpf|cnpj|phone/gi)"
			class="ui-input"
			:class="classObject"
		>
			<label class="label">{{ fieldLabel }}</label>
			<div class="control">
				<input
					v-bind="controlProps"
					type="text"
					class="input"
					:value="value"
					@input="$emit('input', $event.target.value)"
				>
			</div>
			<p
				v-if="!hasError() && get('info')"
				class="help"
			>
				<span v-if="get('info') && !get('validation.$dirty')">
					{{ get('info') }}
				</span>
			</p>
			<p
				v-if="hasError()"
				class="help is-danger"
			>
				<span v-if="!get('validation.minLength')">
					{{ get('error.minLength') }}
				</span>
				<span
					v-if="
						!get('validation.hasNotBeingUsed') &&
							get('validation.$model.length')
					"
				>
					{{ get('error.exists') }}
				</span>
				<span
					v-if="get('validation.$invalid') && !!get('validation.$model.length')"
				>
					{{ get('error.invalid') }}
				</span>
				<span
					v-if="!get('validation.required') && !get('validation.$model.length')"
				>
					{{ get('error.required') }}
				</span>
				<span
					v-if="!get('validation.numeric') && !get('validation.$model.length')"
				>
					{{ get('error.numeric') }}
				</span>
			</p>
		</div>

		<!--*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=
            - TEXTAREA
        *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=-->
		<div
			v-if="type.match(/textarea$/gi)"
			class="ui-input ui-input-textarea"
			:class="classObject"
		>
			<label class="label">{{ fieldLabel }}</label>
			<div class="control">
				<textarea
					v-bind="controlProps"
					:value="value"
					:style="{
						minHeight: `${minheight}px`,
						maxHeight: `${maxheight}px`,
						resize: resized ? 'vertical' : 'none'
					}"
					@input="$emit('input', $event.target.value)"
				/>
			</div>
			<p
				v-if="!hasError() && get('info')"
				class="help"
			>
				<span v-if="get('info') && !get('validation.$dirty')">
					{{ get('info') }}
				</span>
			</p>
			<p
				v-if="hasError()"
				class="help is-danger"
			>
				<span v-if="!get('validation.minLength')">
					{{ get('error.minLength') }}
				</span>
				<span
					v-if="get('validation.$invalid') && !!get('validation.$model.length')"
				>
					{{ get('error.invalid') }}
				</span>
				<span
					v-if="!get('validation.required') && !get('validation.$model.length')"
				>
					{{ get('error.required') }}
				</span>
			</p>
		</div>

		<!--*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=
            - SELECT
        *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=-->
		<div
			v-if="type.match(/select$/gi)"
			class="ui-input ui-input-select"
			:class="classObject"
		>
			<label class="label">{{ fieldLabel }}</label>
			<div class="control">
				<span class="select">
					<select
						:id="id"
						:value="value"
						@input="$emit('input', $event.target.value)"
					>
						<option
							disabled
							value=""
							hidden
							:selected="value == null || value == ''"
						>
							{{ get('placeholder') }}
						</option>
						<option
							v-for="_option in options"
							:key="valueAsKey ? _option.value : randomKey(_option)"
							:selected="_option.value == value"
							:value="_option.value"
						>
							{{ _option.label }}
						</option>
					</select>
				</span>
			</div>
			<p
				v-if="!hasError() && get('info')"
				class="help"
			>
				<span v-if="get('info') && !get('validation.$dirty')">
					{{ get('info') }}
				</span>
			</p>
			<p
				v-if="hasError()"
				class="help is-danger"
			>
				<span v-if="!get('validation.minLength')">
					{{ get('error.minLength') }}
				</span>
				<span
					v-if="get('validation.$invalid') && !!get('validation.$model.length')"
				>
					{{ get('error.invalid') }}
				</span>
				<span
					v-if="!get('validation.required') && !get('validation.$model.length')"
				>
					{{ get('error.required') }}
				</span>
			</p>
		</div>

		<!--*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=
            - SUBMIT
        *=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=*=-->
		<button
			v-if="type.match(/submit$/gi)"
			v-bind="$options.propsData"
			:class="{ ['ui-input button']: true, [className]: true, ...classObject }"
		>
			<slot />
		</button>

		<!--########################################## CUSTOM SELECT ################################################# -->

		<div class="" />
	</div>
</template>

<style lang="scss" scoped src="./index.scss"></style>
